<script>
import "@vueform/multiselect/themes/default.css";
import "flatpickr/dist/flatpickr.css";
import Swal from "sweetalert2";

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import PageHeader from "@/components/page-header";
import Axios from "@/config/axios";
import animationData from "@/components/widgets/msoeawqm.json";
import Lottie from "@/components/widgets/lottie.vue";
import { VueEditor } from "vue3-editor";

export default {
  page: {
    title: "Ramassages",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Ramassages",
      items: [
        {
          text: "Enregistrer un colis",
          href: "/",
        },
        {
          text: "Ramassages",
          active: true,
        },
      ],
      page: 1,
      perPage: 8,
      pages: [],
      value: null,
      statusquestion: "All",
      value1: null,
      searchQuery: null,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
        mode: "range",
      },
      timeConfig: {
        enableTime: false,
        dateFormat: "d M, Y",
      },
      date: null,
      date2: null,
      defaultOptions: {
        animationData: animationData,
      },
      pickupList: [],
      isStatus: null,
      isPayment: null,
      fields: {
        city: "",
        date: "",
        status_code: "0",
        status: "",
      },
    };
  },
  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
    VueEditor,
  },
  created() {},
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },

  mounted() {},
  async beforeRouteEnter() {
    let pickupList = [];

    await Axios.get("/admin/pickups")
      .then(({ data }) => {
        pickupList = data.data;
      })
      .catch((er) => {
        console.log(er);
      });

    localStorage.setItem("@PICKUPS_LIST", JSON.stringify(pickupList));
  },
  beforeMount() {
    this.pickupList = JSON.parse(localStorage.getItem("@PICKUPS_LIST") || "[]");
  },
  methods: {
    async handleGetPickupList() {
      await Axios.get("/admin/pickups")
        .then(({ data }) => {
          this.pickupList = data.data;
        })
        .catch((er) => {
          console.log(er);
        });
    },
    editdata(data) {
      let result = this.pickupList.findIndex((o) => o.id == data.id);
      document.getElementById("edtpickupId").value = this.pickupList[result].id;

      // document.getElementById("editcity").value = this.pickupList[result].city;
      // document.getElementById("editdate").value = this.pickupList[result].date;
      this.fields.city = this.pickupList[result].city;
      this.fields.date = this.pickupList[result].date;
      this.fields.status_code = this.pickupList[result].status_code;
      this.fields.status = this.pickupList[result].status;
    },
    handleUpdate() {
      let result = this.pickupList.findIndex(
        (o) => o.id == document.getElementById("edtpickupId").value
      );
      this.pickupList[result].city = this.fields.city;
      this.pickupList[result].date = this.fields.date;
      this.pickupList[result].status_code = this.fields.status_code;
      this.pickupList[result].status = this.fields.status;

      document.getElementById("edtclosemodal").click();

      Axios.put(
        `/admin/pickups/${document.getElementById("edtpickupId").value}`,
        this.pickupList[result]
      )
        .then(() => {
          this.handleGetPickupList();
        })
        .catch((er) => {
          console.log(er);
        });
    },
    handleCreate() {
      const city = this.fields.city;
      const date = this.fields.date;

      document.getElementById("createclosemodal").click();

      Axios.post(`/admin/pickups`, { city, date })
        .then(() => {
          this.handleGetPickupList();
          this.fields = { city: "", date: "" };
        })
        .catch((er) => {
          console.log(er);
        });
    },
    deletedata(event) {
      Swal.fire({
        title: "Êtes-vous certain ?",
        text: "Cette opération est irréversible !",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "Oui, supprimer !",
      }).then((result) => {
        if (result.value) {
          this.pickupList.splice(this.pickupList.indexOf(event), 1);
          Axios.delete(`/admin/pickups/${event.id}`)
            .then(() => {
              this.handleGetPickupList();
            })
            .catch((er) => {
              console.log(er);
            });
          Swal.fire("Supprimé !", "La date a été effacée.", "success");
        }
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="orderList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Dates de ramassage</h5>
              <div class="flex-shrink-0">
                <button
                  type="button"
                  class="btn btn-success add-btn"
                  data-bs-toggle="modal"
                  id="create-btn"
                  data-bs-target="#showModal"
                >
                  <i class="ri-add-line align-bottom me-1"></i> Creer une date
                </button>
                <!-- <button type="button" class="btn btn-info ms-1">
                  <i class="ri-file-download-line align-bottom me-1"></i> Importer
                </button> -->
              </div>
            </div>
          </div>
          <div
            class="card-body pt-4 border border-dashed border-end-0 border-start-0"
          >
            <div>
              <div class="table-responsive table-card mb-1">
                <table class="table table-nowrap align-middle" id="orderTable">
                  <thead class="text-muted table-light">
                    <tr class="text-uppercase">
                      <th class="sort" data-sort="id">#</th>
                      <th class="sort" data-sort="customer_name">Ville</th>
                      <th class="sort" data-sort="product_name">Date</th>
                      <th class="sort" data-sort="product_name">Statut (Affichage)</th>
                      <th class="sort" data-sort="product_name">Statut</th>
                      <th class="sort" data-sort="city">Actions</th>
                    </tr>
                  </thead>
                  <tbody
                    class="list form-check-all"
                    v-for="(item, index) of pickupList"
                    :key="index"
                  >
                    <tr>
                      <td class="id">
                        {{ index + 1 }}
                      </td>
                      <td class="customer_name" v-html="item.city"></td>
                      <td class="amount" v-html="item.date"></td>
                      <td class="amount" v-html="item.status"></td>
                      <td class="status">
                        <b-badge
                          v-if="item.status_code == '0'"
                          variant="primary"
                        >
                          Ramassage en attente
                        </b-badge>
                        <b-badge
                          v-else-if="item.status_code === '1'"
                          variant="info"
                        >
                          Ramassage en cours
                        </b-badge>
                        <b-badge
                          v-else-if="item.status_code === '2'"
                          variant="success"
                        >
                          Ramassage terminé
                        </b-badge>
                      </td>
                      <td>
                        <ul class="list-inline hstack gap-2 mb-0">
                          <li
                            class="list-inline-item edit"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Edit"
                          >
                            <a
                              class="text-primary d-inline-block edit-item-btn"
                              data-bs-toggle="modal"
                              href="#EditModal"
                              @click="editdata(item)"
                            >
                              <i class="ri-pencil-fill fs-16"></i>
                            </a>
                          </li>
                          <li
                            class="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Remove"
                          >
                            <a
                              class="text-danger d-inline-block remove-item-btn"
                              @click="deletedata(item)"
                            >
                              <i class="ri-delete-bin-5-fill fs-16"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                      <!-- </div> -->
                    </tr>
                  </tbody>
                </table>
                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">Pardon! Aucun résultat trouvé</h5>
                    <p class="text-muted">
                      Nous avons recherché plus de 150 commandes Nous n'en avons
                      trouvé aucune commandes pour votre recherche.
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a
                    class="page-item pagination-prev disabled"
                    href="#"
                    v-if="page != 1"
                    @click="page--"
                  >
                    Precedent
                  </a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li
                      v-for="(pageNumber, index) in pages.slice(
                        page - 1,
                        page + 5
                      )"
                      :key="index"
                      @click="page = pageNumber"
                      :class="{
                        active: pageNumber == page,
                        disabled: pageNumber == '...',
                      }"
                    >
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a
                    class="page-item pagination-next"
                    href="#"
                    @click="page++"
                    v-if="page < pages.length"
                  >
                    Suivant
                  </a>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="showModal"
              tabindex="-1"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Ajouter une date
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-modal"
                    ></button>
                  </div>
                  <form action="#" id="createform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />

                      <div class="mb-4">
                        <label for="delivered-status" class="form-label"
                          >Ville</label
                        >

                        <VueEditor v-model="fields.city" />
                      </div>

                      <div>
                        <label for="delivered-status" class="form-label"
                          >Date</label
                        >
                        <VueEditor v-model="fields.date" />
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          class="btn btn-light"
                          data-bs-dismiss="modal"
                          id="createclosemodal"
                        >
                          Fermer
                        </button>
                        <button
                          type="button"
                          class="btn btn-success"
                          id="add-btn"
                          @click="handleCreate"
                        >
                          Enregistrer
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div
              class="modal modal-xl fade"
              id="EditModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabels"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Editer une date
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-modal"
                    ></button>
                  </div>
                  <form action="#" id="editform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />

                      <div class="mb-3 d-none" id="modal-id">
                        <label for="pickupId" class="form-label">ID</label>
                        <input
                          type="text"
                          pattern="\d*"
                          id="edtpickupId"
                          class="form-control"
                          placeholder="ID"
                          maxlength="3"
                          readonly
                        />
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          <label for="delivered-status" class="form-label">
                            Ville
                          </label>
                          <VueEditor v-model="fields.city" />
                        </div>

                        <div class="col-md-6">
                          <label for="delivered-status" class="form-label">
                            Date
                          </label>
                          <VueEditor v-model="fields.date" />
                        </div>

                        <div class="mb-4">
                          <label for="delivered-status" class="form-label">
                            Statut (Affichage)
                          </label>

                          <select
                            class="form-select"
                            v-model="fields.status_code"
                          >
                            <option value="0">En attente</option>
                            <option value="1">En cours</option>
                            <option value="2">Terminé</option>
                          </select>
                        </div>

                        <div class="mb-4">
                          <label for="delivered-status" class="form-label">
                            Statut
                          </label>
                          <VueEditor v-model="fields.status" />
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          class="btn btn-light"
                          data-bs-dismiss="modal"
                          id="edtclosemodal"
                        >
                          Fermer
                        </button>
                        <button
                          type="button"
                          class="btn btn-success"
                          id="add-btn"
                          @click="handleUpdate"
                        >
                          Modifier
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
